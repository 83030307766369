import { createContext, useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import CustomLink from '@components/global/CustomLink';
import StickyBanner from '@components/global/seasonalPromo/StickyBanner';

import { addPageInfoToSerachParams } from '@helpers/utils';

import { ReactComponent as SVGSearch } from '@public/global/header/search.svg';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteSearch, laravelRouteStartFree } from '@static_components/laravelLinks';
import clsx from 'clsx';
import cookie from 'js-cookie';

import stylesDefault from './Header.module.css';
import stylesDarkMode from './HeaderDarkMode.module.css';
import Logo from './Logo';
import MenuButton from './MenuButton';
import { MenuDialog } from './MenuDialog';
import MenuItem from './MenuItem';
import SubmenuProductsV2 from './SubmenuProductsV2';
import SubmenuResources from './SubmenuResources';

export const MenuContext = createContext<{
    closeMenu: () => void;
}>({ closeMenu: () => { } });

export interface HeaderProps {
    menuButtonComponent?: JSX.Element;
    menuLogIn?: boolean;
    darkMode?: boolean;
    showSeasonalPromoBar?: boolean;
    seasonalPromoColors?: boolean;
    startFreeUrl?: string;
    logoUrl?: string;
    additionalHeaderAttribute?: string;
    sticky?: boolean;
    hidden?: boolean;
    hiddenNav?: boolean;
    onlyPricing?: boolean;
    partnerLogoComponent?: JSX.Element;
}

export const Header = ({
    menuButtonComponent,
    menuLogIn = true,
    darkMode = false,
    showSeasonalPromoBar = false,
    startFreeUrl,
    logoUrl,
    sticky = true,
    hidden,
    additionalHeaderAttribute,
    hiddenNav,
    onlyPricing,
    partnerLogoComponent,
}: HeaderProps) => {

    const { t } = useTranslation('base/_blocks/header_menu');
    const { query, locale, pathname } = useRouter();
    const [stuck, setStuck] = useState(false);
    const [isHeaderHidden, setIsHeaderHidden] = useState(hidden || false);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const stickyRef = useRef<HTMLInputElement>(null);
    const styles = darkMode ? stylesDarkMode : stylesDefault;
    const shouldButtonBeVisible = !pathname.startsWith("/pricing");
    let bannerData = { placement: 'marketing_pages', i18nKey: 'descFeaturePage' };
    if (pathname.startsWith('/blog') || pathname.startsWith('/resources')) {
        bannerData = { placement: 'resources', i18nKey: 'descBlogPage' }
    } else if (pathname.startsWith('/pricing')) {
        bannerData = { placement: 'pricing', i18nKey: 'descPricingPage' }
    } else if (pathname === '') {
        bannerData = { placement: 'homepage', i18nKey: 'descHomePage' }
    }

    const { pageProperties } = useAppContext();

    useEffect(() => {
        if (Object.keys(query).includes('freeTrial')) {
            cookie.set('freeTrial', 'true');
            setIsHeaderHidden(true);
        } else if (hidden) {
            setIsHeaderHidden(true);
        } else {
            setIsHeaderHidden(cookie.get('freeTrial') !== undefined ? true : false);
        }
    }, [query, hidden]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            setStuck(!entries[0].isIntersecting);
        });

        if (stickyRef.current) {
            observer.observe(stickyRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [stickyRef]);
    return !isHeaderHidden ? (
        <>
            <div
                ref={stickyRef}
                style={{ position: 'sticky', top: '-1px', zIndex: 999999 }}
            ></div>
            <header
                className={clsx(
                    styles.header,
                    !sticky && styles['not-sticky'],
                    stuck && styles.stuck,
                    darkMode ? styles.darkMode : '',
                    isMobileMenuVisible ? styles.mobileMenuHidden : '',
                )}
                data-additional-attribute={additionalHeaderAttribute}
            >
                <div className={styles.inner}>
                    <MenuContext.Provider value={{ closeMenu: () => setIsMobileMenuVisible(false) }}>
                        <Logo
                            className={styles.logo}
                            darkMode={darkMode}
                            href={logoUrl}
                        />
                        {partnerLogoComponent && partnerLogoComponent}
                        {!hiddenNav || onlyPricing ? (
                            <>
                                <button
                                    className={[
                                        styles.mobileMenuToggler,
                                        isMobileMenuVisible ? styles.menuVisible : null,
                                    ]
                                        .filter(Boolean)
                                        .join(' ')}
                                    onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
                                    aria-label="menu"
                                ></button>
                                <nav
                                    className={[styles.nav, isMobileMenuVisible ? styles.visible : null]
                                        .filter(Boolean)
                                        .join(' ')}
                                >
                                    {!onlyPricing && (
                                        <MenuItem
                                            className={styles.item}
                                            darkMode={darkMode}
                                        >
                                            <MenuDialog
                                                label={t('2022HeaderMenuLabelProduct')}
                                                darkMode={darkMode}
                                            >
                                                <SubmenuProductsV2 darkMode={darkMode} />
                                            </MenuDialog>
                                        </MenuItem>
                                    )}
                                    {pathname !== '/pricing' ? (
                                        <MenuItem
                                            className={styles.item}
                                            darkMode={darkMode}
                                        >
                                            <CustomLink
                                                href={{
                                                    pathname: 'pricing',
                                                }}
                                                onClick={() => {
                                                    setIsMobileMenuVisible(false);
                                                }}
                                            >
                                                {t('2022HeaderMenuLabelPricing')}
                                            </CustomLink>
                                        </MenuItem>
                                    ) : (
                                        <MenuItem
                                            className={styles.item}
                                            darkMode={darkMode}
                                        >
                                            <span>{t('2022HeaderMenuLabelPricing')}</span>
                                        </MenuItem>
                                    )}
                                    {!onlyPricing && (
                                        <>
                                            <MenuItem
                                                className={styles.item}
                                                darkMode={darkMode}
                                            >
                                                <MenuDialog
                                                    label={t('2022HeaderMenuLabelResources')}
                                                    darkMode={darkMode}
                                                >
                                                    <SubmenuResources darkMode={darkMode} />
                                                </MenuDialog>
                                            </MenuItem>
                                            {pathname !== '/max' ? (
                                                <>
                                                    {pathname.startsWith('/max') ? (
                                                        <MenuItem
                                                            className={styles.item}
                                                            darkMode={darkMode}
                                                        >
                                                            <CustomLink href={{ pathname: 'max' }}>
                                                                {t('2022HeaderMenuLabelEnterprise')}
                                                            </CustomLink>
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem
                                                            className={styles.item}
                                                            darkMode={darkMode}
                                                        >
                                                            <CustomLink
                                                                href={{ pathname: 'max/book-a-demo' }}
                                                                data-ats-main-menu="book-a-demo"
                                                                role="book-a-demo"
                                                            >
                                                                {t('2022HeaderMenuLabelBookADemo')}
                                                            </CustomLink>
                                                        </MenuItem>
                                                    )}
                                                    <MenuItem className={styles.item}>
                                                        <a
                                                            className={styles.searchLink}
                                                            href={laravelRouteSearch(locale)}
                                                        >
                                                            <span>{t('2022HeaderMenuLabelSearch')}</span>
                                                            <SVGSearch
                                                                width="15"
                                                                height="15"
                                                            />
                                                        </a>
                                                    </MenuItem>
                                                </>
                                            ) : (
                                                <MenuItem
                                                    className={clsx(styles.item, styles.itemEnterprice)}
                                                    darkMode={darkMode}
                                                >
                                                    <span className={clsx(styles.enterprice)}>
                                                        {t('2022HeaderMenuLabelEnterprise')}
                                                    </span>
                                                </MenuItem>
                                            )}
                                        </>
                                    )}
                                    {menuLogIn && (
                                        <MenuItem
                                            align="end"
                                            className={styles.item}
                                            darkMode={darkMode}
                                        >
                                            <a
                                                className={styles.loginButton}
                                                href={`https://app.getresponse.com/login?lang=${locale}`}
                                            >
                                                {t('2022HeaderMenuLabelLogIn')}
                                            </a>
                                        </MenuItem>
                                    )}
                                </nav>
                                {menuButtonComponent ? (
                                    menuButtonComponent
                                ) : (
                                    <MenuButton>
                                        <a
                                            href={addPageInfoToSerachParams(
                                                pageProperties,
                                                startFreeUrl ? startFreeUrl : laravelRouteStartFree(locale),
                                            )}
                                            data-ats-main-menu="create_free_trial_link"
                                            data-track="create_free_trial_link"
                                            className={styles.button}
                                            title={t('2022HeaderMenuLabelSignUp')}
                                        >
                                            {t('2022HeaderMenuLabelSignUp')}
                                        </a>
                                    </MenuButton>
                                )}
                            </>
                        ) : null}
                    </MenuContext.Provider>
                </div>
            </header>
            {showSeasonalPromoBar && <StickyBanner isButtonVisible={shouldButtonBeVisible} bannerData={bannerData} />}
        </>
    ) : null;
};

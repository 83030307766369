import React, { useEffect } from 'react';

import Trans from '@components/Trans';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './StickyBanner.module.css';
import blueDecorationAVIF from './assets/blueDecoration@1x.avif';
import blueDecorationAVIF2x from './assets/blueDecoration@2x.avif';
import redDecorationAVIF from './assets/redDecoration@1x.avif';
import redDecorationAVIF2x from './assets/redDecoration@2x.avif';
import thirtyPercentAVIF from './assets/thirtyPercent@1x.avif';
import thirtyPercentWEBP from './assets/thirtyPercent@1x.webp';
import thirtyPercentAVIF2x from './assets/thirtyPercent@2x.avif';
import thirtyPercentWEBP2x from './assets/thirtyPercent@2x.webp';

interface StickyBannerProps {
    isButtonVisible: boolean | null;
    bannerData: { placement: string; i18nKey: string };
}

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        dataLayer: unknown;
    }
}

export default function StickyBanner({ isButtonVisible, bannerData: { placement, i18nKey } }: StickyBannerProps) {
    const { t } = useTranslation('promo/valentinesDay/TopBanner/index');
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'vday25_promo_banner_viewed',
            placement,
        });
    }, [placement]);

    const handleClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'vday25_promo_banner_clicked',
            placement,
        });
    };

    return (
        <div className={styles.valentinesBanner}>
            <div className={styles.container}>
                <div className={clsx(styles.box,  !isButtonVisible ? styles.desktopBox : '')}>
                    <picture>
                        <source
                            type="image/avif"
                            srcSet={`${thirtyPercentAVIF.src} 1x, ${thirtyPercentAVIF2x.src} 2x`}
                        />
                        <source
                            type="image/webp"
                            srcSet={`${thirtyPercentWEBP.src} 1x, ${thirtyPercentWEBP2x.src} 2x`}
                            sizes="(min-width: 733px) 733px, 100vw"
                        />
                        <img
                            width={thirtyPercentAVIF.width}
                            height={thirtyPercentAVIF.height}
                            decoding="async"
                            loading="lazy"
                            alt=""
                            src={thirtyPercentAVIF.src}
                            style={{ maxWidth: thirtyPercentAVIF.width, display: 'block', margin: '0 auto' }}
                        />
                    </picture>
                    <div className={styles.backgroundBox}>
                        <picture>
                            <source
                                type="image/avif"
                                srcSet={`${redDecorationAVIF.src} 1x, ${redDecorationAVIF2x.src} 2x`}
                            />
                            <img
                                width={redDecorationAVIF.width}
                                height={redDecorationAVIF.height}
                                decoding="async"
                                loading="lazy"
                                alt=""
                                src={redDecorationAVIF.src}
                                style={{ maxWidth: redDecorationAVIF.width }}
                            />
                        </picture>
                    </div>
                </div>
                <div className={clsx(styles.box, isButtonVisible ? styles.desktopBox : '')}>
                    <p className={styles.typography}>
                        <Trans
                            i18nKey={i18nKey}
                            components={{
                                strong: <strong />,
                                br: <br />,
                            }}
                            ns="promo/valentinesDay/TopBanner/index"
                        />
                    </p>
                </div>
                {isButtonVisible && (
                    <div className={clsx(styles.box)}>
                        <CTAStartFreeCreateAccount
                            size="small"
                            label={t('button')}
                            color="red"
                            className={styles.button}
                            onClick={handleClick}
                            arrow={true}
                        />
                        <div className={clsx(styles.backgroundBox, styles.blueDecoration)}>
                            <picture>
                                <source
                                    type="image/avif"
                                    srcSet={`${blueDecorationAVIF.src} 1x, ${blueDecorationAVIF2x.src} 2x`}
                                />
                                <img
                                    width={blueDecorationAVIF.width}
                                    height={blueDecorationAVIF.height}
                                    decoding="async"
                                    loading="lazy"
                                    alt=""
                                    src={blueDecorationAVIF.src}
                                    style={{ maxWidth: blueDecorationAVIF.width }}
                                />
                            </picture>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
